<template>
<!--  commentaire a supprimer -->
  <div class="taro-funnel">
    <div :class="$style.taroFunnelScope  + ' container'">
      <div class="loading-taro" v-if="loadingFonts"><span></span></div>
      <div v-else></div>
      <div v-if="!loadingFonts">
        <!--Affichage du titre-->
        <transition name="banner-transition">
          <FirstVisitBanner v-if="displayFirstVisitBanner" @close="closeBanner"/>
        </transition>
        <PopupTravaux v-if="$store.state.popupReset"></PopupTravaux>
        <p :class="$style.titre" v-show="currentPage.titre">{{ currentPage.titre }}</p>
        <!--Composant affiché dynamiquement en fonction de la page courante-->
        <div :class="$style.pageContainer">
          <div v-if="displayButtonTravaux" :class="$style.buttonTravaux">
            <p @click="displayPopup">Tous les travaux</p>
          </div>
          <component v-bind:is="currentPageName" :parameters="$store.state.widgetParams.entryParameters.parameters" :goToEnd="$store.state.widgetParams.entryParameters.goToEnd" @changePage="closeBanner"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Home from './views/Home'

import navigationMixin from './mixins/navigation-mixin'
import { mapGetters, mapState } from 'vuex'
import WebFont from 'webfontloader'
import BskuService from './services/navigation/BskuService'
import PopupTravaux from './components/PopupTravaux'
import FirstVisitBanner from './components/FirstVisitBanner'
import HomeAlternative2023 from "@/views/AlternativeHome2023.vue";
import AlternativeHome2023 from "@/views/AlternativeHome2023.vue";

export default {
  name: 'App',
  props: {
    token: {
      required: false,
      type: String,
      default () {
        // Token homly you
        return 'KM25Eqb2JnpyNGuuPoU_xoNaAwLMQDmUwHkEsJcFKe8'
      },
      note: 'Ce paramètre permet d\'identifier la plateforme utilisatrice du simulateur, par default la plateforme utilisatrice est homly-you'
    },
    callback: {
      type: String,
      required: true
    },
    progressCallback: {
      type: String,
      required: false
    },
    onError: {
      type: String,
      required: false
    },
    bsku: {
      type: String,
      required: false,
      default: null
    },
    parameters: {
      type: String,
      required: false
    },
    goToEnd: {
      type: Boolean,
      required: false,
      default: false
    },
    landing: {
      type: String,
      required: false,
      default: null
    },
    trackingData: {
      type: String,
      required: false
    },
    alternativeHome: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    getPageIndex (newValue, oldValue) {
      if (this.displayFirstVisitBanner && newValue !== oldValue) {
        this.closeBanner()
      }
    }
  },
  data () {
    return {
      loadingFonts: true,
      // Utilisé dans l'apiUrl du store car requit dans la route de chiffrage de l'API
      filter: 'published',
      displayFirstVisitBanner: true
    }
  },
  computed: {
    ...mapGetters(['currentPage', 'getPageIndex']),
    ...mapState(['currentPageIndex']),
    currentPageName () {
      if (this.currentPage.name === 'home') {
        // Au plus simple pour le changement de home
        return 'alternative-home-2023'
      }
      return this.currentPage.name
    },
    displayButtonTravaux () {
      return this.currentPage.name !== 'home'
    },
    isClient () {
      if (!this.trackingData) {
        return false
      }
      let trackingData
      try {
        trackingData = JSON.parse(this.trackingData)
      } catch (e) {
        trackingData = this.trackingData
      }
      if (trackingData.statut_client) {
        return trackingData.statut_client === 'oui'
      }
      return false
    },
    isModification () {
      return typeof this.parameters === 'object' && Object.keys(this.parameters).length > 0
    }
  },
  created () {
    this.checkFirstVisite()
  },
  mounted () {
    // Loading des fonts
    const WebFontConfig = {
      google: {
        families: ['Ubuntu:300,400,700,500', 'Roboto:300,400,700,500']
      },
      classes: false,
      active: () => {
        console.log('fonts actives, start app')
        this.loadingFonts = false
      },
      inactive: () => {
        this.loadingFonts = false
        console.log('fonts inactives, start app anyway')
      }
    }
    WebFont.load(WebFontConfig)

    this.$store.commit('setWidgetParams', {
      filter: this.filter,
      token: this.token,
      callback: this.callback,
      progressCallback: this.progressCallback,
      onError: this.onError,
      entryParameters: {
        parameters: this.parameters,
        goToEnd: this.goToEnd,
        trackingData: this.trackingData
      }
    })

    console.log('app mounted')

    if (this.landing && this.validateLanding(this.landing)) {
      this.$store.dispatch('initNavigation', 'landing')
      this.goToLanding(this.landing)
      return
    }

    if (BskuService.isBskuRedirectToLanding(this.bsku) && !this.parameters) {
      this.$store.dispatch('initNavigation', 'landing')
      this.goToLanding(BskuService.getFormCode(this.bsku))
      return
    }

    const params = this.parameters ? JSON.parse(this.parameters) : {}
    const bsku = params.bsku_origin ? params.bsku_origin : this.bsku

    if (!BskuService.isValid(bsku)) {
      this.$store.commit('resetPages')
      this.$store.dispatch('goToHome')
      return
    }
    // parameter.origin stock la page d'arrivée au widget lors du retour a un formulaire pour permettre le retour a la page d'arrivée au dela du formulaire
    if (this.goToEnd && params.origin) {
      if (params.origin.includes('landing')) {
        this.$store.dispatch('initNavigation', 'landing')
        this.$store.commit('setLanding', params.origin)
      } else {
        this.$store.dispatch('initNavigation', params.origin)
      }
    } else {
      this.$store.dispatch('initNavigation', 'formulaire')
    }

    this.goToForm(BskuService.getFormCode(bsku), BskuService.getSubFormCode(bsku))
  },
  methods: {
    validateLanding (landing) {
      return this.$store.state.landingsList.includes(landing)
    },
    displayPopup () {
      this.$store.commit('setPopupReset')
    },
    checkFirstVisite () {
      this.displayFirstVisitBanner = this.getFirstVisiteLocalStorage() && !this.isClient && !this.isModification
    },
    closeBanner () {
      if (this.displayFirstVisitBanner) {
        try {
          localStorage.setItem('firstVisite', 'false')
        } catch (e) {
        }
        this.checkFirstVisite()
      }
    },
    getFirstVisiteLocalStorage () {
      let toReturn = false
      try {
        // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
        toReturn = localStorage.getItem('firstVisite') !== 'false'
      } catch (e) {
        toReturn = false
      }

      return toReturn
    }
  },
  mixins: [navigationMixin],
  components: {
    FirstVisitBanner: FirstVisitBanner,
    home: Home,
    'alternative-home-2023': AlternativeHome2023,
    PopupTravaux: PopupTravaux,
    formulaire: () => import('./views/Formulaire'),
    landing: () => import('./views/Landing')
  },
  mq: {
    phone: '(max-width: 576px)',
    tablet: '(max-width: 767px)'
  }
}
</script>

<style lang="scss">
  .taro-funnel {
    .banner-transition-leave-active {
      transition: max-height .5s ease-out, padding .5s ease-out;
    }
    .banner-transition-leave-to {
      padding: 0 20px 0 13px !important;
      max-height: 0 !important;
    }
    $container-max-widths: (
      xl: 1170px
    );
    @import '~bootstrap/scss/bootstrap-grid.scss';
    @import '~bootstrap/scss/bootstrap-reboot.scss';
    @import '~bootstrap/scss/_images.scss';
    margin: -24px;
    .row:after,
    .row:before,
    .container:before,
    .container:after {
      content: none;
    }
    p {
      margin-bottom: 0;
    }
    input[type='number'] {
      -moz-appearance:textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    [placeholder]:focus::-webkit-input-placeholder,
    input:focus::placeholder {
      opacity: 0;
      color: transparent;
    }
    button:focus {
      outline: none;
    }
  }

  @keyframes rotating {
    from
    {
      transform: rotate(0deg);
    }
    to
    {
      transform: rotate(360deg);
    }
  }
  strong {
    font-weight: 700;
  }
  .loading-taro {
    margin: 100px auto;
    span {
      display: inline-block;
      background-image: $loading-image;
      background-repeat: no-repeat;
      background-position: center;
      animation: rotating 1s linear infinite;
      width: 40px;
      height: 40px;
    }
  }
  .taro-funnel {
    h4 {
      line-height: inherit;
      font-size: 1rem;
    }
    .container {
      padding-left: 0;
      padding-right: 0;
      .row {
        margin: 0;
      }
    }
    .lazyload,
    .lazyloading {
      opacity: 0;
      transition: opacity 0.4s ease-out;
    }
    .lazyloaded {
      opacity: 1;
      transition: opacity 0.4s ease-out;
    }
    svg.fill-svg path {
      fill: $secondary-color;
    }
    svg.stroke-svg path, svg.stroke-svg ellipse {
      stroke: $primary-color;
    }
  }
</style>

<style lang="scss" module>
  .button-travaux {
    height: 26px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    >p {
      color: #49A0A2;
      font-weight: 700;
      font-size: 0.80rem;
      border-bottom: 2px solid #49A0A2;
      padding-bottom: 2px;
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
    }
  }
  .taro-funnel-scope {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    font-family: $primary-font, sans-serif;
    color: $text-color;
    font-size: 16px;
    position: relative;
    touch-action: manipulation;
    *, *:after, *:before {
      font-family: $primary-font, sans-serif;
    }
    .super-titre {
      font-family: $secondary-font, sans-serif;
      font-size: 1.9em;
      padding-bottom: 25px;
    }
    .titre {
      text-transform: uppercase;
      font-size: 1.6em;
      margin-bottom: 20px;
      margin-top: 20px;
      font-weight: normal;
      font-family: $primary-font, sans-serif;
    }
    .page-container {
      padding: 24px;
      min-height: 200px;
    }
    .nav {
      margin-top: 25px;
      .nav-button {
        user-select: none;
        cursor: pointer;
        text-transform: capitalize;
        width: 250px;
        height: 68px;
        line-height: 68px;
        border-radius: 3px;
        border: 1px solid $light-grey;
        transition: background-color 0.5s ease;
        background-color: #fff;
      }
      .nav-button:hover {
        color: #fff;
        background-color: $primary-color;
      }
    }
  }
</style>
