<template>
  <div :class="$style.popupTravaux">
    <div :class="$style.crossClose" @click="closePopUp">+</div>
    <img :src="warning">
    <p>En quittant cette page, vous perdrez les informations saisies.</p>
    <p :class="$style.highlight">Voulez-vous vraiment quitter cette page ?</p>
    <div :class="$style.action">
      <button :class="$style.confirm" @click="goHome">Confirmer</button>
      <button :class="$style.cancel" @click="closePopUp">Annuler</button>
    </div>
  </div>
</template>

<script>
import warning from '../assets/warning.png'
import progressMixin from '../mixins/progress-mixin'

export default {
  name: 'PopupTravaux',
  mixins: [progressMixin],
  data () {
    return {
      warning
    }
  },
  methods: {
    closePopUp () {
      this.$store.commit('setPopupReset')
    },
    goHome () {
      this.$store.commit('resetPages')
      this.$store.dispatch('goToHome')
      this.updateProgress(true)
      this.closePopUp()
    }
  }
}
</script>

<style lang="scss" module>
  .popup-travaux {
    position: fixed;
    transform: translate(-50%, 0);
    left: 50%;
    top: 0;
    z-index: 1000;
    max-width: 900px;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .cross-close {
      position: absolute;
      display: block;
      height: 60px;
      width: 60px;
      top: 0;
      left: 0;
      font-size: 2em;
      transform: rotate(45deg);
      cursor: pointer;
    }
    >p {
      font-size: 1em;
      font-family: "Ubuntu", sans-serif;
      font-weight: 300;
      margin-bottom: 30px !important;
      &.highlight {
        font-weight: normal;
      }
    }
    >img {
      margin-bottom: 30px;
    }
    .action {
      button {
        outline: none;
        height: 54px;
      }
      position: absolute;
      bottom: 20px;
      padding: 0 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .confirm {
        border: none;
        background-color: #49A0A2;
        height: 54px;
        border-radius: 2px !important;
        border: none;
        font-weight: 500;
        color: #ffffff;
        width: 100%;
        max-width: 420px;
        margin-bottom: 10px;
      }
      .cancel {
        font-size: 0.9em;
        border: none;
        background: none;
        display: inline-block;
          text-decoration: underline;
        padding: 0 120px;
        margin: 0;
      }
    }
  }

@media (min-width: 700px) {
  .popup-travaux {
    .action {
      position: relative;
      margin-top: 80px;
    }
  }
}
</style>
