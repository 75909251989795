<template>
  <div :class="$style.homeAlternative2023">
    <div :class="$style.head">
      <div :class="$style.explanation">
        <div :class="$style.explanationLine"></div>
        <div :class="$style.step">
          <span :class="$style.number">1</span>
          <p :class="$style.descriptionEstimation">
            Décrivez et estimez<br><b>vos travaux</b>
          </p>
        </div>
        <div :class="$style.step">
          <span :class="$style.number">2</span>
          <p :class="$style.descriptionValidation">
            Validez votre projet<br>avec <b>un conseiller</b>
          </p>
        </div>
        <div :class="$style.step">
          <span :class="$style.number">3</span>
          <p :class="$style.descriptionChoix">
            <b>Choisissez les Pros</b> que <br>vous voulez rencontrer
          </p>
        </div>
      </div>
    </div>
    <div :class="$style.content">
      <h1 :class="$style.title" v-html="'Quels&nbsp;travaux&nbsp;souhaitez&#8209;vous réaliser&nbsp;?'"></h1>
      <h2 :class="$style.subtitle">Travaux les plus demandés</h2>
      <div :class="$style.linkGlobalContainer">
        <home-link-global-alternative-2023
          v-for="travaux in travauxGlobaux"
          :key="travaux.name"
          :travaux="travaux"
          v-on:click="goToCodeSkipSubFormChoice(travaux.code)">
        </home-link-global-alternative-2023>
      </div>
      <div :class="$style.linkAllPieceContainer">
        <a :class="$style.linkAllPiece" @click="goToCodeSkipSubFormChoice('landing_piece')">
          <span>Voir toutes les pièces</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.55 5.55757H9.1223L6.1721 8.54832C5.95733 8.76604 5.95732 9.11912 6.17196 9.33671C6.38674 9.55443 6.73516 9.55443 6.94994 9.33671L10.8389 5.3942C10.8644 5.36828 10.8873 5.3397 10.9073 5.30931C10.9165 5.29524 10.923 5.28004 10.931 5.26541C10.9401 5.24812 10.9505 5.23167 10.9578 5.21341C10.9656 5.1946 10.97 5.17508 10.9755 5.15557C10.9801 5.1401 10.986 5.12532 10.9891 5.10943C10.9963 5.07319 11 5.03667 11 5.00001C11 4.99959 10.9999 4.99917 10.9999 4.99875C10.9997 4.96265 10.9962 4.92641 10.9891 4.89087C10.9858 4.87414 10.9797 4.85867 10.9748 4.84222C10.9695 4.82368 10.9654 4.80486 10.9579 4.78688C10.95 4.76751 10.9392 4.74994 10.9293 4.73168C10.9219 4.71802 10.916 4.70408 10.9075 4.69098C10.8874 4.66018 10.8643 4.63146 10.8386 4.6054L6.9498 0.663313C6.73503 0.445586 6.38674 0.445586 6.17196 0.663173C5.95719 0.8809 5.95719 1.23397 6.17196 1.45184L9.12216 4.44245H0.55C0.246263 4.44245 0 4.6921 0 5.00001C0 5.30792 0.246263 5.55757 0.55 5.55757Z"
                  fill="#49A0A2"/>
          </svg>
        </a>
      </div>
      <h2 :class="$style.subtitle">Ou Travaux à la carte</h2>
      <div :class="$style.linkListContainer">
        <home-link-list-alternative-2023
          v-for="travaux in autresTravauxLinks"
          :key="travaux.name"
          :travaux="travaux"
          :isOpen="travaux.is_open"
          v-on:click="goToCodeSkipSubFormChoice">
        </home-link-list-alternative-2023>
      </div>
    </div>
  </div>
</template>

<script>
import HomeLinkListAlternative2023 from '../components/form_link/HomeLinkListAlternative2023.vue'
import HomeLinkGlobalAlternative2023 from '../components/form_link/HomeLinkGlobalAlternative2023'

import navigationMixin from '../mixins/navigation-mixin'
import analyticsMixin from '../mixins/analytics-mixin'

import DescriptionProjet from '../assets/home/explanation/description-projet.svg'
import ValiderProjet from '../assets/home/explanation/valider-projet.svg'
import ChoisirPro from '../assets/home/explanation/choisir-pro.svg'

import RenovationEnergetiquePicto from '../assets/home/picto/reno-energetique-picto.png'

import MaisonGlobalImg from '../assets/home/global/maison.png'
import AppartementGlobalImg from '../assets/home/global/appartement.png'
import RenoEnergetiqueGlobalImg from '../assets/home/renovations/reno-energetique.png'
import ExterieurGlobalImg from '../assets/home/global/exterieur.png'
import FenetreGlobalImg from '../assets/home/global/fenetre.png'
import IsolationGlobalImg from '../assets/home/global/isolation.png'
import PompeChaleurGlobalImg from '../assets/home/global/pompe-chaleur.png'
import SalleDeBainGlobalImg from '../assets/home/global/salle-de-bain.png'

import GarageImg from '../assets/home/travaux_pieces/garage.png'
import ComblesImg from '../assets/home/travaux_pieces/combles.png'
import CuisineImg from '../assets/home/travaux_pieces/cuisine.png'
import SalleDeBainImg from '../assets/home/travaux_pieces/salle_de_bain.png'
import SalonImg from '../assets/home/travaux_pieces/salon.png'
import ToilettesImg from '../assets/home/travaux_pieces/toilettes.png'

import FenetrePortesImg from '../assets/home/autres_travaux/fenetres-portes.jpg'
import PeintureRevetementsImg from '../assets/home/autres_travaux/peinture-revetements.jpg'
import ChauffageClimatisationImg from '../assets/home/autres_travaux/chauffage-climatisation.jpg'
import TerrasseExterieurImg from '../assets/home/autres_travaux/terrasse-exterieur.jpg'
import FacadeImg from '../assets/home/autres_travaux/facade.jpg'
import PlomberieImg from '../assets/home/autres_travaux/plomberie.jpg'
import IsolationImg from '../assets/home/autres_travaux/isolation.jpg'
import VerriereImg from '../assets/home/autres_travaux/verriere.jpg'
import GrosOeuvreImg from '../assets/home/autres_travaux/gros-oeuvre.jpg'
import ToitureImg from '../assets/home/autres_travaux/toiture.jpg'
import ElectriciteImg from '../assets/home/autres_travaux/electricite.jpg'
import AccessImg from '../assets/home/autres_travaux/accessibilite.jpg'
import RenovationEnergetiqueTravaux from '../assets/home/autres_travaux/renovation-energetique.jpg'

export default {
  name: 'Home',
  components: {
    HomeLinkListAlternative2023,
    HomeLinkGlobalAlternative2023,
  },
  mixins: [navigationMixin, analyticsMixin],
  data () {
    return {
      DescriptionProjet,
      ValiderProjet,
      ChoisirPro,
      travauxGlobaux: [
        {
          name: 'renovation_maison',
          code: 'PACK_FUNNEL_MAISON',
          label: 'Maison',
          img: MaisonGlobalImg
        },
        {
          name: 'renovation_appartement',
          code: 'PACK_FUNNEL_APPARTEMENT',
          label: 'Appartement',
          img: AppartementGlobalImg
        },
        {
          name: 'piece_sdb',
          code: 'PACK_FUNNEL_SDB',
          label: 'Salle de bain',
          img: SalleDeBainGlobalImg
        },
        {
          name: 'amenagement_exterieur',
          code: 'PACK_AMENAGEMENT_EXTERIEUR',
          label: 'Terrasse et extérieur',
          img: ExterieurGlobalImg
        },
        {
          name: 'menuiserie',
          code: 'PACK_MENUISERIE',
          label: 'Fenêtres - Portes',
          img: FenetreGlobalImg
        },
        {
          name: 'renovation_energetique',
          code: 'PACK_RENOVATION_ENERGETIQUE',
          label: 'Economies<br>d\'énergies',
          img: RenoEnergetiqueGlobalImg,
          picto: RenovationEnergetiquePicto
        },
        {
          name: 'isolation',
          code: 'PACK_RENOVATION_ENERGETIQUE-ISOLATION',
          label: 'Isolation',
          img: IsolationGlobalImg
        },
        {
          name: 'pompe_a_chaleur',
          code: 'PACK_CHAUFFAGE_CLIMATISATION-POMPE_A_CHALEUR',
          label: 'Pompe à chaleur',
          img: PompeChaleurGlobalImg
        }
      ],
      autresTravauxLinks: [
        {
          name: 'plomberie',
          label: 'Salle de bain - WC',
          code: 'PACK_FUNNEL_SDB',
          img: PlomberieImg,
          is_open: true,
          items: [
            {
              name: 'sdb',
              code: 'PACK_FUNNEL_SDB',
              label: 'Salle de bain complète'
            },
            {
              name: 'WC',
              code: 'PACK_FUNNEL_WC',
              label: 'WC'
            },
            {
              name: 'douche',
              code: 'PACK_FUNNEL_SIMPLE_DOUCHE',
              label: 'Douche'
            },
            {
              name: 'baignoire',
              code: 'PACK_FUNNEL_SIMPLE_BAIGNOIRE',
              label: 'Baignoire'
            },
            {
              name: 'radiateur_seche_serviettes',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-RADIATEUR',
              label: 'Radiateur - Sèche-serviettes'
            },
          ]
        },
        {
          name: 'TERRASSE_EXTERIEUR',
          code: 'PACK_AMENAGEMENT_EXTERIEUR',
          label: 'Terrasse - Extérieur',
          img: TerrasseExterieurImg,
          items: [
            {
              name: 'terrasse',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-TERRASSE',
              label: 'Terrasse'
            },
            {
              name: 'allee',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-ALLEE',
              label: 'Allée'
            },
            {
              name: 'cloture',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-CLOTURE',
              label: 'Clôture'
            },
            {
              name: 'mur_cloture',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-MUR_CLOTURE',
              label: 'Mur de clôture'
            },
            {
              name: 'portail',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-PORTAIL',
              label: 'Portail'
            }
          ]
        },
        {
          name: 'pack_menuiserie',
          code: 'PACK_MENUISERIE',
          label: 'Fenêtres - Portes',
          img: FenetrePortesImg,
          items: [
            {
              name: 'fenetre',
              code: 'PACK_MENUISERIE-FENETRE',
              label: 'Fenêtre'
            },
            {
              name: 'porte_fenetre',
              code: 'PACK_MENUISERIE-PORTE_FENETRE',
              label: 'Porte-fenêtre'
            },
            {
              name: 'baie_coulissante',
              code: 'PACK_MENUISERIE-BAIE_COULISSANTE',
              label: 'Baie coulissante'
            },
            {
              name: 'porte_dentree',
              code: 'PACK_MENUISERIE-PORTE_ENTREE',
              label: 'Porte d\'entrée'
            },
            {
              name: 'porte_garage',
              code: 'PACK_MENUISERIE-PORTE_GARAGE',
              label: 'Porte de garage'
            }
          ]
        },
        {
          name: 'chauffage',
          label: 'Chauffage - Climatisation',
          legend: 'Chaudière, radiateur…',
          img: ChauffageClimatisationImg,
          code: 'PACK_CHAUFFAGE_CLIMATISATION',
          items: [
            {
              name: 'pompe_a_chaleur',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-POMPE_A_CHALEUR',
              label: 'Pompe à chaleur'
            },
            {
              name: 'poele_insert_bois',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-POELE_INSERT_BOIS',
              label: 'Poêle et insert à bois'
            },
            {
              name: 'chaudiere',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-CHAUDIERE',
              label: 'Chaudière'
            },
            {
              name: 'climatisation',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-CLIMATISATION',
              label: 'Climatisation'
            },
            {
              name: 'ventilation',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-VENTILATION',
              label: 'Ventilation'
            }
          ]
        },
        {
          name: 'toiture',
          code: 'PACK_RENOVATION_TOITURE',
          label: 'Toiture',
          img: ToitureImg,
          items: [
            {
              name: 'fenetre_de_toit',
              code: 'PACK_RENOVATION_TOITURE-MENUISERIE',
              label: 'Fenêtre de toit'
            },
            {
              name: 'couverture',
              code: 'PACK_RENOVATION_TOITURE-COUVERTURE',
              label: 'Toiture'
            },
            {
              name: 'charpente',
              code: 'PACK_RENOVATION_TOITURE-CHARPENTE',
              label: 'Charpente'
            },
            {
              name: 'isolation_thermique',
              code: 'PACK_RENOVATION_TOITURE-ISOLATION',
              label: 'Isolation thermique'
            },
            {
              name: 'etancheite_toit_terrasse',
              code: 'PACK_RENOVATION_TOITURE-ETANCHEITE_TOIT_TERRASSE',
              label: 'Etanchéité toit-terrasse'
            }
          ]
        },
        {
          name: 'peinture_revetement',
          label: 'Peinture - Revêtements',
          code: 'landing_revetement',
          img: PeintureRevetementsImg,
          items: [
            {
              name: 'peinture_interieure',
              code: 'PACK_FUNNEL_SIMPLE_PEINTURE_INTERIEURE',
              label: 'Peinture intérieure'
            },
            {
              name: 'carrelage',
              code: 'PACK_FUNNEL_SIMPLE_CARRELAGE',
              label: 'Carrelage'
            },
            {
              name: 'parquet',
              code: 'PACK_FUNNEL_SIMPLE_PARQUET_BOIS',
              label: 'Parquet et rénovation de parquet'
            },
            {
              name: 'sol_stratifie_sol_pvc',
              code: 'PACK_FUNNEL_SIMPLE_SOL_STRAT',
              label: 'Sol stratifié et sol PVC'
            },
            {
              name: 'beton_cire',
              code: 'PACK_SIMPLE_BETON_CIRE',
              label: 'Béton ciré'
            },
          ]
        },
        {
          name: 'MUR_CLOISONS',
          label: 'Verrière - Mur - Plafond',
          img: VerriereImg,
          items: [
            {
              name: 'verriere',
              code: 'PACK_SIMPLE_VERRIERE',
              label: 'Verrière'
            },
            {
              name: 'cloison',
              code: 'PACK_FUNNEL_SIMPLE_CLOISON',
              label: 'Cloison'
            },
            {
              name: 'demolition_mur',
              code: 'PACK_FUNNEL_SIMPLE_DEMOLITION_MUR',
              label: 'Démolition de mur'
            },
            {
              name: 'plafond',
              code: 'PACK_FUNNEL_SIMPLE_ISO_PLAFOND',
              label: 'Isolation acoustique du plafond'
            }
          ]
        },
        {
          name: 'ISOLATION',
          label: 'Isolation',
          code: 'PACK_ISOLATION',
          img: IsolationImg,
          items: [
            {
              name: 'mur',
              code: 'PACK_ISOLATION-MUR',
              label: 'Isolation des murs'
            },
            {
              name: 'COMBLES_PERDUS',
              code: 'PACK_ISOLATION-COMBLES_PERDUS',
              label: 'Combles perdus'
            },
            {
              name: 'COMBLES_AMENAGES',
              code: 'PACK_ISOLATION-COMBLES_AMENAGES',
              label: 'Combles aménagés'
            },
            {
              name: 'plancher_intermediaire',
              code: 'PACK_ISOLATION-PLANCHER_INTERMEDIAIRES',
              label: 'Isolation du plancher'
            },
            {
              name: 'toit-terrasse',
              code: 'PACK_ISOLATION-TOIT_TERRASSE',
              label: 'Toit-terrasse'
            },
          ]
        },
        {
          name: 'facade',
          label: 'Façade',
          code: 'landing_reno_facade',
          img: FacadeImg,
          items: [
            {
              name: 'ravalement',
              code: 'PACK_SIMPLE_RENO_FACADE',
              label: 'Ravalement'
            },
            {
              name: 'eduit_crepi',
              code: 'PACK_SIMPLE_ENDUIT_FACADE',
              label: 'Enduit ou crépi'
            },
            {
              name: 'isolation_exterieur',
              code: 'PACK_SIMPLE_ISOLATIONS_MURS_EXTERIEURS',
              label: 'Isolation thermique par l\'extérieur'
            },
            {
              name: 'bardage',
              code: 'PACK_SIMPLE_BARDAGE',
              label: 'Bardage'
            },
          ]
        },
        {
          name: 'GROS_OEUVRE',
          label: 'Gros oeuvre',
          img: GrosOeuvreImg,
          items: [
            {
              name: 'demolition_mur',
              code: 'PACK_FUNNEL_SIMPLE_DEMOLITION_MUR',
              label: 'Démolition de mur'
            },
            {
              name: 'chape_beton',
              code: 'PACK_FUNNEL_SIMPLE_CHAPE_BETON',
              label: 'Chape béton'
            },
            {
              name: 'dalle_beton',
              code: 'PACK_FUNNEL_SIMPLE_DALLE_BETON',
              label: 'Dalle béton'
            },
          ]
        },
        {
          name: 'ELECTRICITE',
          label: 'Electricité',
          img: ElectriciteImg,
          items: [
            {
              name: 'mise_normes_logement',
              code: 'PACK_FUNNEL_SIMPLE_ELECTRICITE_LOGEMENT',
              label: 'Mise aux normes d\'un logement'
            },
            {
              name: 'mise_normes_piece',
              code: 'PACK_FUNNEL_SIMPLE_ELECTRICITE_PIECE',
              label: 'Mise aux normes d\'une pièce'
            },
          ]
        },
        {
          name: 'RENOVATION_ENERGETIQUE',
          label: 'Rénovation énergétique',
          code: 'PACK_RENOVATION_ENERGETIQUE',
          img: RenovationEnergetiqueTravaux,
          picto: RenovationEnergetiquePicto,
          items: [
            {
              name: 'reno_energetique',
              code: 'PACK_RENOVATION_ENERGETIQUE',
              label: 'Rénovation énergétique globale'
            },
            {
              name: 'reno_energetique_isolation',
              code: 'PACK_RENOVATION_ENERGETIQUE-ISOLATION',
              label: 'Isolation'
            },
            {
              name: 'reno_energetique_chauffage_climatisation',
              code: 'PACK_RENOVATION_ENERGETIQUE-CHAUFFAGE',
              label: 'Chauffage'
            },
            {
              name: 'reno_energetique_menuiserie',
              code: 'PACK_RENOVATION_ENERGETIQUE-FENETRES',
              label: 'Fenêtre - Portes'
            },
            {
              name: 'reno_energetique_ventilation',
              code: 'PACK_RENOVATION_ENERGETIQUE-VENTILATION',
              label: 'Ventilation'
            }
          ]
        },
        {
          name: 'ACCESSIBILITE',
          label: 'Accessibilité du logement',
          code: 'landing_accessibilite',
          img: AccessImg,
          items: [
            {
              name: 'accessibilite_sdb',
              code: 'PACK_ACCESSIBILITE_SDB',
              label: 'Salle de bain sécurisée'
            },
            {
              name: 'accessibilite_baignoire_douche',
              code: 'PACK_ACCESSIBILITE_BAIGNOIRE_DOUCHE',
              label: 'Remplacer baignoire par 1 douche'
            },
            {
              name: 'accessibilite_ascenseur',
              code: 'PACK_ACCESSIBILITE_ASCENSEUR',
              label: 'Monte-escalier et ascenseur'
            },
            {
              name: 'accessibilite_chambre_garage',
              code: 'PACK_ACCESSIBILITE_CHAMBRE_GARAGE',
              label: 'Transformer un garage en chambre'
            },
            {
              name: 'accessibilite_toilette',
              code: 'PACK_ACCESSIBILITE_TOILETTE',
              label: 'Toilettes accessibles'
            },
          ]
        }
      ]
    }
  },
  methods: {
    goToCodeSkipSubFormChoice (code) {
      this.pushDataLayer({
        eventaction: 'validated-step',
        eventcategory: 'estimation',
        eventlabel: 'accueil',
        interface: 'accueil',
        numerodetape: this.stepIndex,
        BSKU: '',
        label_besoin: '',
        page_pia: 'oui'
      })
      this.goToNextStep(code, true)
    }
  },
  mounted () {
    // import du composant formulaire pour le preloader dès que la home est mounted
    import('../views/Formulaire')
      .then(() => {
        console.log('form page preloaded')
      })
    this.$store.commit('setLanding', '')
    this.$store.commit('setAdvancedForm', '')
    if (this.$store.state.navigation.resetNav) {
      this.$store.commit('setResetNav', false)
    } else {
      this.pushDataLayer({
        eventaction: 'display',
        eventcategory: 'estimation',
        eventlabel: 'accueil',
        interface: 'accueil',
        numerodetape: this.stepIndex,
        BSKU: '',
        label_besoin: '',
        page_pia: 'oui'
      })
      this.$store.commit('setResetNav', false)
    }
  }
}
</script>

<style lang="scss" module>
.home-alternative-2023 {
  text-align: left;
  padding-bottom: 20px;
  .head {
    max-width: 768px;
    margin: 0 auto;
    padding: 17px 0 22px;
    .explanation {
      margin: 0 auto;
      max-width: 582px;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-around;
      .explanation-line {
        top: 19px;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #49A0A2;
        z-index: 0;
      }
      .step {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 12px 0;
        justify-content: center;
        align-items: center;
        width: fit-content;
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          aspect-ratio: 1/1;
          font-weight: 700;
          font-size: 19.2px;
          line-height: 22px;
          background-color: #2E4057;
          color: #ffffff;
          border-radius: 50%;
        }
        .description {
          width: fit-content;
          font-size: 13.3px;
          line-height: 15px;
          text-align: center;
          color: #1B1918;
          b {
            font-weight: 700;
          }
        }
        .description-estimation {
          @extend .description;
          font-weight: 300;
        }
        .description-validation {
          @extend .description;
          font-weight: 400;
        }
        .description-choix {
          @extend .description;
          font-weight: 500;
        }
      }
    }
    @media (max-width: 767px) {
      padding: 0;
      .explanation {
        display: none;
      }
    }
  }
  .content {
    max-width: 737px;
    width: 100%;
    margin: 10px auto 0;
    .title, .subtitle {
      font-weight: 700;
    }
    .title {
      color: #1B1918;
      font-size: 23px;
      line-height: normal;
      margin-bottom: 32px;
      font-style: normal;
      font-weight: 700;
    }
    .subtitle {
      color: #1B1918;
      font-size: 19.2px;
      text-align: center;
      margin: 20px 0;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .link-all-piece-container {
      display: block;
      margin: 32px auto 32px;
      text-align: center;
    }
    .link-all-piece {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      color: #56ABAE;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      font-style: normal;
      line-height: normal;
      text-decoration-line: underline;
    }
    .link-list-container {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 32px 0;
    }
    .link-global-container {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 29px;
      flex-wrap: wrap;
      row-gap: 32px;
    }
    .link-piece-container {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 20px;
    }
    @media (max-width: 767px) {
      .title {
        font-size: 24px;
        margin-bottom: 32px;
      }
      .subtitle {
        font-size: 20px;
        margin: 20px 0;
      }
      .link-list-container {
        justify-content: center;
        flex-direction: column;
        gap: 12px;
      }
      .link-global-container {
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: calc((152px * 2) + 20px);
        gap: 20px 12px;
      }
      .link-piece-container {
        max-width: calc((102px * 3) + (12px * 2));
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 20px 10px;
      }
      .link-all-piece-container {
        margin: 20px auto 32px;
      }
    }
    @media (max-width: 388px) {
      .link-piece-container {
        justify-content: center;
        gap: 20px 12px;
      }
    }
    @media (max-width: 376px) {
      .link-global-container {
        justify-content: center;
      }
    }
  }
}

@media (min-width: 1280px) {
  .home-alternative-2023 {
    .content {
      position: relative;
    }
  }
}
</style>
