<template>
  <div :class="$style.linkGlobalCard" @click="$emit('click')">
    <img :class="$style.linkGlobalCardImg" :src="travaux.img" :alt="travaux.label" />
    <div :class="$style.linkGlobalCardInfos">
      <img v-if="travaux.picto" :class="$style.linkGlobalCardPicto" :src="travaux.picto" :alt="travaux.label" />
      <p :class="$style.linkGlobalCardTitle" v-html="travaux.label"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeLinkGlobal',
  props: {
    travaux: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.link-global-card {
  cursor: pointer;
  width: 162px;
  height: 195px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  background: #ffffff;
  .link-global-card-img {
    width: 162px;
    height: 130px;
  }
  .link-global-card-infos {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    position: relative;
    .link-global-card-picto {
      position: absolute;
      left: 4px;
    }
    .link-global-card-title {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1B1918;
      &:hover {
        color: #49A0A2;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 767px) {
    width: 152px;
    .link-global-card-img {
      width: 152px;
    }
  }
}
</style>
