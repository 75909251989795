import * as Sentry from '@sentry/vue'
export default {
  manageCallbackError (error, component) {
    this.manageSentryComponentError(error, component)

    let errorMessage = 'erreur'

    if (error.name) {
      errorMessage += ' name:' + error.name
    }

    if (error.response && error.response.status) {
      errorMessage += ' status:' + error.response.status
    }

    if (error.message) {
      errorMessage += ' message:' + error.message
    }

    if (error.config && error.config.baseURL) {
      errorMessage += ' url:' + error.config.baseURL
    }

    window[component.$store.state.widgetParams.onError](errorMessage)
  },
  manageSentryComponentError (error, component) {
    const advancedFormComponent = this.getAdvancedFormComponent(component)
    const contexts = {}
    if (advancedFormComponent) {
      contexts.model = JSON.stringify(advancedFormComponent.model)
      contexts.advancedForm = advancedFormComponent.$store?.state?.advancedForm ? advancedFormComponent.$store.state.advancedForm : null
      contexts.currentPage = advancedFormComponent.currentPage?.name ? advancedFormComponent.currentPage.name : null
    }

    this.manageSentryError(error, contexts)
  },
  manageSentryError (error, contexts) {
    Sentry.captureException(error, { contexts: { contexts: contexts } })
  },
  getAdvancedFormComponent (component) {
    if (!component) {
      return null
    }

    if (component.$options.name === 'AdvancedForm') {
      return component
    }

    return this.getAdvancedFormComponent(component.$parent)
  }
}
