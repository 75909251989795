import Vuex from 'vuex'
import Vue from 'vue'
import navigation from './navigation-store'
import bskuConfig from '../config/bsku'
import landing from '../config/landing.json'
import ErrorService from '../services/error/ErrorService'

import {
  getValue
} from '../advanced_forms/common'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    popupReset: false,
    ouvrage: '',
    codeOuvrage: '',
    formulaire: '',
    advancedFormsList: [
      'PACK_FUNNEL_CHAMBRE',
      'PACK_FUNNEL_BUREAU',
      'PACK_FUNNEL_SEJOUR',
      'PACK_FUNNEL_APPARTEMENT',
      'PACK_FUNNEL_MAISON',
      'PACK_FUNNEL_EXTENSION',
      'PACK_RENOVATION_TOITURE',
      'PACK_FUNNEL_CUISINE',
      'PACK_FUNNEL_SDB',
      'PACK_FUNNEL_BUANDERIE',
      'PACK_FUNNEL_ENTREE',
      'PACK_FUNNEL_WC',
      'PACK_FUNNEL_COMBLES',
      'PACK_FUNNEL_SIMPLE_BAIGNOIRE',
      'PACK_FUNNEL_SIMPLE_CARRELAGE',
      'PACK_FUNNEL_SIMPLE_CLIMATISATION',
      'PACK_FUNNEL_SIMPLE_DOUCHE',
      'PACK_FUNNEL_SIMPLE_MEUBLES_SDB',
      'PACK_FUNNEL_SIMPLE_PORTE_FENETRES',
      'PACK_FUNNEL_SIMPLE_WC',
      'PACK_FUNNEL_SIMPLE_TERRASSE_BETON',
      'PACK_FUNNEL_SIMPLE_CHAPE_BETON',
      'PACK_FUNNEL_SIMPLE_CHAUDIERE_GAZ',
      'PACK_FUNNEL_SIMPLE_CLOISON',
      'PACK_FUNNEL_SIMPLE_DALLE_BETON',
      'PACK_FUNNEL_SIMPLE_DEMOLITION_MUR',
      'PACK_FUNNEL_SIMPLE_FENETRE_TOIT',
      'PACK_FUNNEL_SIMPLE_PARQUET_BOIS',
      'PACK_FUNNEL_SIMPLE_PEINTURE_INTERIEURE',
      'PACK_FUNNEL_SIMPLE_PORTE_INTERIEUR',
      'PACK_FUNNEL_SIMPLE_ISO_COMBLES_AMENAGES',
      'PACK_FUNNEL_SIMPLE_ELECTRICITE_PIECE',
      'PACK_FUNNEL_SIMPLE_ELECTRICITE_LOGEMENT',
      'PACK_FUNNEL_SIMPLE_ISO_COMBLES_PERDUS',
      'PACK_FUNNEL_SIMPLE_ISO_PLAFOND',
      'PACK_FUNNEL_SIMPLE_PORTE_ENTREE',
      'PACK_FUNNEL_SIMPLE_RADIATEUR_SECHE',
      'PACK_FUNNEL_SIMPLE_TERRASSE_CARRELEE',
      'PACK_FUNNEL_SIMPLE_MUR_MACONNE',
      'PACK_FUNNEL_SIMPLE_BALLON_EAU_CHAUDE',
      'PACK_FUNNEL_SIMPLE_CHAUFFAGE_BOIS',
      'PACK_SIMPLE_ENDUIT_FACADE',
      'PACK_FUNNEL_SIMPLE_ISOLATIONS_MURS_EXTERIEURS',
      'PACK_SIMPLE_RENO_FACADE',
      'PACK_FUNNEL_SIMPLE_SOL_STRAT',
      'PACK_MENUISERIE',
      'PACK_AMENAGEMENT_EXTERIEUR',
      'PACK_ISOLATION',
      'PACK_CHAUFFAGE_CLIMATISATION',
      'PACK_RENOVATION_ENERGETIQUE',
      'PACK_SIMPLE_ISOLATIONS_MURS_EXTERIEURS',
      'PACK_SIMPLE_VERRIERE',
      'PACK_SIMPLE_BETON_CIRE',
      'PACK_SIMPLE_BARDAGE',
      'PACK_ACCESSIBILITE_BAIGNOIRE_DOUCHE',
      'PACK_ACCESSIBILITE_SDB',
      'PACK_ACCESSIBILITE_ASCENSEUR',
      'PACK_ACCESSIBILITE_TOILETTE',
      'PACK_ACCESSIBILITE_CHAMBRE_RDC',
      'PACK_ACCESSIBILITE_CHAMBRE_GARAGE',
      'PACK_PIECE_GARAGE'
    ],
    advancedForm: '',
    subForm: null,
    skipSubFormChoice: false,
    advancedFormDatas: '',
    landingsList: landing,
    landing: '',
    formId: '',
    questions: [],
    results: {},
    entryAnswers: null,
    widgetParams: {},
    catPart: '',
    userInfos: {
      first_name: '',
      last_name: '',
      email: '',
      maturity: ''
    }
  },
  getters: {
    apiUrl: state => {
      const apiSuffix = state.widgetParams.token + '/' + state.widgetParams.filter + '/'
      if (process.env.VUE_APP_API_URL) {
        return process.env.VUE_APP_API_URL + apiSuffix
      } else {
        return 'https://api.taro.lmsg.integration.pia-production.fr/v1/' + apiSuffix
      }
    },
    questionContext: state => {
      var context = {}
      state.questions.forEach(question => {
        if (question.validity) {
          if (question.type === 'radio') {
            if (question.answer.value) {
              context[question.code] = question.answer.value.label
            }
          } else {
            if (isNaN(question.answer.value)) {
              context[question.code] = question.answer.value
            } else {
              context[question.code] = +question.answer.value // convertir une string number en number
            }
          }
        }
      })
      return context
    },
    getPageByModelKey: state => (modelKey) => {
      let currentPage = {}
      state.advancedFormDatas.form.pages.forEach(page => {
        if (page.type === 'dynamicList') {
          if (page.model === modelKey) {
            currentPage = page
          }
        } else if (page.type === 'nested') {
          page.nestedForm.questions.forEach(question => {
            page.nestedForm.items.forEach(item => {
              if (item.model[question] === modelKey) {
                currentPage = page
              }
            })
          })
        } else {
          page.questions.forEach(question => {
            if (question.model === modelKey) {
              currentPage = page
            }
          })
        }
      })
      return currentPage
    },
    getElements: (state, getters) => {
      if (state.advancedFormDatas.modelExporter) {
        return state.advancedFormDatas.modelExporter.export(state.advancedFormDatas.model)
      }
    },
    getPrestations: (state, getters) => {
      if (state.advancedFormDatas.modelExporter) {
        return state.advancedFormDatas.modelExporter.export(state.advancedFormDatas.model)
      }
    },
    getAides: (state) => {
      if (state.advancedFormDatas.aidesBuilder) {
        return state.advancedFormDatas.aidesBuilder.build(state.advancedFormDatas.model)
      }
    },
    getTva: (state) => {
      if (state.advancedFormDatas.model.tva) {
        return state.advancedFormDatas.model.tva
      }
      return 'tva_20'
    },
    getPriceCoefficient: (state) => {
      if (state.advancedFormDatas.model.price_coefficient) {
        return state.advancedFormDatas.model.price_coefficient
      }
      return 1
    },
    getCodePostal: (state) => {
      if (state.advancedFormDatas.model.geographic_question_insee) {
        return state.advancedFormDatas.model.geographic_question_insee.postalCode
      }
      return null
    },
    getAdvancedResult: (state, getters) => {
      if (state.advancedFormDatas.modelToExport) {
        const results = {}
        state.advancedFormDatas.modelToExport.forEach(key => {
          const upperCaseKey = key.toUpperCase()
          const value = typeof state.advancedFormDatas.model[key] === 'function'
            ? getValue(state.advancedFormDatas.model[key]())
            : getValue(state.advancedFormDatas.model[key])

          if (value !== null) {
            results[upperCaseKey] = value
          }
        })
        return results
      } else {
        return getters.getFilteredAdvancedResult
      }
    },
    getFilteredAdvancedResult: (state, getters) => {
      const results = {}
      const model = state.advancedFormDatas.model
      const availablePages = state.advancedFormDatas.form.pages.filter(page => page.isAvailable(model))
      const availableQuestionsModel = []
      availablePages.forEach(page => {
        if (typeof page.questions === 'function') {
          availableQuestionsModel.push(page.model)
        } else if (page.type === 'nested') {
          page.nestedForm.questions.forEach(question => {
            page.nestedForm.items.forEach(item => {
              availableQuestionsModel.push(item.model[question.modelName])
            })
          })
        } else {
          page.questions.forEach(question => {
            if (question.isAvailable(model)) {
              availableQuestionsModel.push(question.model)
            }
          })
        }
      })
      availableQuestionsModel.forEach(key => {
        const upperCaseKey = key.toUpperCase()
        if (Array.isArray(model[key])) { // Questions a choix multiples
          if (getters.getPageByModelKey(key).isAvailable(model)) {
            model[key].forEach(choice => {
              if (typeof choice.isAvailable === 'function') { // On renvoi uniquement les choix available
                if (choice.isAvailable(model)) {
                  results[choice.name] = choice.quantity
                }
              } else {
                results[choice.name] = choice.quantity
              }
            })
          } else {
            model[key].forEach(choice => { results[choice.name] = 0 })
          }
        } else if (model[key] instanceof Object) { // Questions radio a un choix
          if (getters.getPageByModelKey(key).type === 'dynamicList') {
            const tempResults = {}
            getters.getPageByModelKey(key).questions(model).forEach(question => {
              const valueName = model[key][question.model].name
              if (Array.isArray(model[key][question.model])) {
                model[key][question.model].forEach(value => {
                  if (tempResults[value.name]) {
                    tempResults[value.name] += 1
                  } else {
                    tempResults[value.name] = 1
                  }
                })
              } else {
                if (tempResults[valueName]) {
                  tempResults[valueName] += 1
                } else {
                  tempResults[valueName] = 1
                }
              }
            })
            results[upperCaseKey] = tempResults
          } else {
            if (typeof model[key].isAvailable === 'function') { // On renvoi uniquement les choix available
              if (model[key].isAvailable(model)) {
                results[upperCaseKey] = model[key].name
              }
            } else {
              results[upperCaseKey] = model[key].name
            }
          }
        } else if (model[key] !== null && model[key] !== '') {
          results[upperCaseKey] = model[key]
        }
      })
      const modelKeysFunctions = Object.keys(model).filter(key => model[key] instanceof Function)
      modelKeysFunctions.forEach(key => {
        results[key.toUpperCase()] = model[key]()
      })
      return results
    },
    currentBsku (state) {
      const code = state.advancedForm.toUpperCase()
      const funnelInfo = Object
        .entries(bskuConfig.bskus)
        .find((item) => item[1].code === code)

      if (!funnelInfo) {
        const error = new Error("le code '" + code + "' n'existe pas dans la liste des bskus (bsku.json).")
        // https://github.com/getsentry/sentry-javascript/issues/2791
        const displayingState = state
        delete displayingState.advancedFormsList
        delete displayingState.landingsList
        ErrorService.manageSentryError(error, {
          index_state: JSON.stringify(displayingState),
          navigation_state: JSON.stringify(navigation.state)
        })

        store.commit('resetPages')
        store.dispatch('goToHome')
      }

      return funnelInfo[0]
    },
    currentBskuTracking (state) {
      const funnelInfo = Object
        .entries(bskuConfig.bskus)
        .find((item) => item[1].code === state.advancedForm.toUpperCase())
      return funnelInfo[1].tracking
    }
  },
  mutations: {
    setPopupReset (state) {
      state.popupReset = !state.popupReset
    },
    setWidgetParams (state, params) {
      state.widgetParams = params
    },
    emptyEntryParams (state) {
      state.widgetParams.entryParameters.parameters = null
      state.widgetParams.entryParameters.goToEnd = false
    },
    setOuvrage (state, ouvrage) {
      state.ouvrage = ouvrage
    },
    setCodeOuvrage (state, code) {
      state.codeOuvrage = code
    },
    setFormulaire (state, form) {
      state.formulaire = form
      state.formId = form.id
    },
    setAdvancedForm (state, form) {
      if (form) {
        state.advancedForm = form.toLowerCase()
      } else {
        state.advancedForm = ''
      }
    },
    setSubForm (state, subForm) {
      state.subForm = subForm.toUpperCase()
    },
    setSkipSubFormChoice (state, skipSubFormChoice) {
      state.skipSubFormChoice = skipSubFormChoice
    },
    setAdvancedFormDatas (state, datas) {
      state.advancedFormDatas = datas
    },
    setFormId (state, formId) {
      state.formId = formId
    },
    setQuestions (state, questions) {
      state.questions = questions
    },
    handleAnswer (state, answer) {
      state.questions[answer.index].answer = answer.value
      state.questions[answer.index].validity = answer.value.validity
    },
    setCalculResult (state, results) {
      state.results = results
    },
    setCatPart (state, catpart) {
      state.catPart = catpart
    },
    setEntryAnswers (state, answers) {
      state.entryAnswers = answers
    },
    setUserInfos (state, infos) {
      state.userInfos = infos
    }
  },
  modules: {
    navigation: navigation
  }
})

export default store
