<template>
  <div key="banner" :class="$style.banner">
    <div :class="$style.stepContainer">
      <div :class="$style.step">
        <div :class="$style.number">1</div>
        <div :class="$style.description">Décrivez et estimez vos travaux</div>
      </div>
      <div :class="$style.step">
        <div :class="$style.number">2</div>
        <div :class="$style.description">Validez votre projet avec un conseiller</div>
      </div>
      <div :class="$style.step">
        <div :class="$style.number">3</div>
        <div :class="$style.description">Choisissez les Pros que vous voulez rencontrer</div>
      </div>
    </div>
    <div :class="$style.actions">
      <a :href="linkFindOutMore" target="_blank" rel="noopener">En savoir plus</a>
      <span @click="closeBanner">ok</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FirstVisitBanner',
  data () {
    return {
      firstVisite: false,
      linkFindOutMore: process.env.VUE_APP_FIND_OUT_MORE_URL || 'https://lmsgsbxsitestoragea.z28.web.core.windows.net/devis-travaux-professionnels-agrees/'
    }
  },
  methods: {
    closeBanner () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.banner-transition-leave-active {
  transition: max-height .5s ease-out, padding .5s ease-out;
}
.banner-transition-leave-to {
  padding: 0 20px 0 13px !important;
  max-height: 0 !important;
}
</style>

<style lang="scss" module>
@media (min-width: 768px) {
  .banner {
    display: none;
  }
}
@media (max-width: 767px) {
  .banner {
    max-height: 212px;
    margin-bottom: 4px;
    position: relative;
    z-index: 99;
    background: #ffffff;
    padding: 24px 20px 26px 13px;
    overflow: hidden;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    .stepContainer {
      color: #1B1918;
      margin-bottom: 24px;
      .step {
        position: relative;
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 12px;
          &:before {
            content: '';
            position: absolute;
            z-index: 0;
            width: 3px;
            height: 30px;
            background: #49A0A2;
            top: 100%;
            left: 14px;
          }
        }
        .number {
          font-family: 'Roboto', sans-serif !important;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #ffffff;
          background: #49A0A2;
          min-width: 30px;
          max-width: 30px;
          height: 30px;
          font-weight: 900;
          font-size: 14px;
          line-height: 65px;
          border-radius: 50%;
          border: 4px solid #ffffff;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        }
        .description {
          font-family: 'Roboto', sans-serif !important;
          vertical-align: center;
          height: max-content;
          margin-left: 11px;
          font-weight: 400;
          font-size: 14px;
          text-align: left;
        }
      }
    }
    .actions {
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      :first-child {
        margin-left: 79px;
      }
      a, span {
        font-family: 'Roboto', sans-serif !important;
        cursor: pointer;
        text-decoration: none;
        line-height: 28px;
        color: #49A0A2;
      }
      span {
        font-size: 18px;
        text-transform: uppercase;
      }
      a {
        font-size: 14px;
        text-transform: none;
        &:hover {
          color: #49A0A2;
        }
      }
    }
  }
}
</style>
