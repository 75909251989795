const progressMixin = {
  methods: {
    updateProgress (reset = false) {
      console.log('update progress')
      const progressCallback = this.$store.state.widgetParams.progressCallback
      if (typeof window[progressCallback] === 'function') {
        if (!reset) {
          console.log('update progress no reset : ' + this.progressIndex + ', ' + this.pages.filter(page => page.isAvailable(this.model)).length)
          window[progressCallback](this.progressIndex, this.pages.filter(page => page.isAvailable(this.model)).length)
        } else {
          console.log('update progress reset')
          window[progressCallback](0, 0)
        }
      }
    }
  }
}

export default progressMixin
