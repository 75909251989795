const navigationStore = {
  state: {
    currentPageIndex: 0,
    resetNav: false,
    homeTravauxCategorie: null,
    landing: '',
    pages: [
      {
        name: 'home',
        isAvailable () {
          return true
        }
      },
      {
        name: 'landing',
        isAvailable (state) {
          return state.landing !== ''
        }
      },
      {
        name: 'formulaire',
        isAvailable () {
          return true
        }
      }
    ]
  },
  getters: {
    getPageIndex (state) {
      return state.currentPageIndex
    },
    currentPage (state) {
      return state.pages[state.currentPageIndex]
    },
    getPageIndexByName: (state) => (pageName) => {
      return state.pages.map(p => p.name).indexOf(pageName)
    },
    isFirstPage (state) {
      return state.currentPageIndex === 0
    },
    showFirstStep (state) {
      return state.pages[0].name === 'home'
    }
  },
  mutations: {
    setCurrentPage (state, index) {
      state.currentPageIndex = index
    },
    setResetNav (state, reset) {
      state.resetNav = reset
    },
    setLanding (state, landing) {
      state.landing = landing
    },
    setHomeTravauxCategorie (state, categorie) {
      state.homeTravauxCategorie = categorie
    },
    splitNav (state, stepIndex) {
      state.pages.splice(0, stepIndex)
    },
    resetPages (state) {
      state.pages = [
        {
          name: 'home',
          isAvailable () {
            return true
          }
        },
        {
          name: 'landing',
          isAvailable (state) {
            return state.landing !== ''
          }
        },
        {
          name: 'formulaire',
          isAvailable () {
            return true
          }
        }
      ]
    }
  },
  actions: {
    nextPage (context) {
      if (context.state.currentPageIndex < context.state.pages.length - 1) {
        context.dispatch('changePage', context.state.currentPageIndex + 1)
      } else {
        context.dispatch('changePage', 0)
      }
    },
    prevPage ({ state, dispatch }, nbStep = 1) {
      if (state.currentPageIndex > 0) {
        if (state.currentPageIndex <= 2 && !state.landing && !state.homeTravauxCategorie) {
          dispatch('goToHome')
        } else if (state.currentPageIndex <= 1 && state.landing && !state.homeTravauxCategorie) {
          dispatch('goToHome')
        } else if (state.pages[state.currentPageIndex - nbStep].isAvailable(state)) {
          dispatch('changePage', state.currentPageIndex - nbStep)
        } else {
          dispatch('prevPage', nbStep + 1)
        }
      } else {
        dispatch('changePage', 0)
      }
    },
    goToPage (context, pageName) {
      const index = context.getters.getPageIndexByName(pageName)
      context.dispatch('changePage', index)
    },
    changePage (context, pageIndex) {
      if (context.state.pages[pageIndex].name === 'home' || context.state.pages[pageIndex].name === 'landing') {
        console.log('change page is home or landing')
        context.commit('emptyEntryParams')
      }
      context.commit('setCurrentPage', pageIndex)
    },
    goToHome (context) {
      // Attention goToHome veux dire revenir à la première page du parcours qui peux etre une landing ou la première page d'un formulaire.
      context.commit('setHomeTravauxCategorie', '')
      context.commit('emptyEntryParams')
      context.commit('setCurrentPage', 0)
    },
    initNavigation ({ state, getters, commit }, pageName) {
      commit('splitNav', getters.getPageIndexByName(pageName))
    }
  }
}

export default navigationStore
