const analyticsMixin = {
  computed: {
    stepIndex () {
      return this.$store.state.navigation.pages
        .filter(page => page.isAvailable(this.$store.state.navigation))
        .map(availablePage => availablePage.name)
        .indexOf(this.$store.getters.currentPage.name)
    },
    trackingData () {
      try {
        return JSON.parse(this.$store.state.widgetParams.entryParameters.trackingData)
      } catch (e) {
        console.log('erreur dans json trackindData : ' + e)
        return {}
      }
    }
  },
  methods: {
    pushDataLayer (data) {
      if (window.dataLayer && typeof window.dataLayer.push === 'function') {
        window.dataLayer.push(Object.assign(data, this.trackingData))
      }
    },
    replaceChar (value) {
      const charsToReplace = [
        {
          char: '<br/>',
          subsitute: '-'
        },
        {
          char: '<strong>',
          subsitute: ''
        },
        {
          char: '</strong>',
          subsitute: ''
        },
        {
          char: 'é',
          subsitute: 'e'
        },
        {
          char: '\'',
          subsitute: '-'
        },
        {
          char: '\u00A0',
          subsitute: '-'
        },
        {
          char: '’',
          subsitute: '-'
        },
        {
          char: ',',
          subsitute: ''
        },
        {
          char: 'è',
          subsitute: 'e'
        },
        {
          char: 'é',
          subsitute: 'e'
        },
        {
          char: 'ê',
          subsitute: 'e'
        },
        {
          char: 'à',
          subsitute: 'a'
        },
        {
          char: 'ç',
          subsitute: 'c'
        },
        {
          char: 'ù',
          subsitute: 'u'
        },
        {
          char: '!',
          subsitute: ''
        },
        {
          char: '\\?',
          subsitute: ''
        },
        {
          char: '<br>',
          subsitute: '-'
        },
        {
          char: 'ô',
          subsitute: 'o'
        },
        {
          char: '\\s',
          subsitute: '-'
        },
        {
          char: '/',
          subsitute: ''
        },
        {
          char: '---',
          subsitute: '-'
        },
        {
          char: '--',
          subsitute: '-'
        },
        {
          char: '\\(',
          subsitute: ''
        },
        {
          char: '\\)',
          subsitute: ''
        }
      ]

      if (!value) {
        return ''
      }

      charsToReplace.forEach(char => {
        // https://stackoverflow.com/questions/62825358/javascript-replaceall-is-not-a-function-type-error
        value = value.replace(new RegExp(char.char, 'g'), char.subsitute)
      })

      return value
    },
    reformatLabel (value) {
      value = this.replaceChar(value)

      if (value.slice(value.length - 1) === '-') {
        value = value.slice(0, -1)
      }
      return value.toLowerCase()
    }
  }
}

export default analyticsMixin
