import { mapGetters, mapState } from 'vuex'

const navigationMixin = {
  computed: {
    ...mapGetters(['isFirstPage']),
    ...mapState(['advancedFormsList'])
  },
  methods: {
    goToForm (formCode, subFormCode, skipSubFormChoice = false) {
      this.scrollToTop()
      if (this.$store.getters.currentPage && this.$store.getters.currentPage.name === 'home') {
        this.$store.commit('setLanding', '')
      }
      console.log('form loading : ' + formCode)
      this.$store.commit('setAdvancedForm', formCode)
      if (subFormCode !== undefined) {
        this.$store.commit('setSubForm', subFormCode)
      }
      this.$store.commit('setSkipSubFormChoice', skipSubFormChoice)
      this.$store.dispatch('goToPage', 'formulaire')
    },
    goToLanding (landing) {
      this.scrollToTop()
      this.$store.commit('setLanding', landing)
      this.$store.dispatch('goToPage', 'landing')
    },
    nextPage () {
      this.scrollToTop()
      this.$store.dispatch('nextPage')
    },
    prevPage () {
      this.scrollToTop()
      this.$store.dispatch('prevPage')
    },
    scrollToTop () {
      if (window.scrollTo && typeof window.scrollTo === 'function') {
        window.scrollTo(0, 0)
      }
    },
    goToNextStep (code, skipSubFormChoice = false) {
      if (this.$store.state.advancedFormsList.includes(code)) {
        this.goToForm(code, '')
      } else if (this.$store.state.landingsList.includes(code)) {
        this.goToLanding(code)
      } else {
        if (code.indexOf('-') !== -1) {
          // Chargement d'un sous parcours du formulaire
          const subForm = code.split('-')[1]
          const form = code.split('-')[0]
          this.goToForm(form, subForm, skipSubFormChoice)
        } else {
          throw new Error('Le code ' + code + ' n\'est présent ni dans la liste des formulaires ni des landing')
        }
      }
    }
  }
}

export default navigationMixin
