/* eslint-disable */
const choice = {
  JNSP: 'JNSP',
  AUCUN: 'AUCUN'
}

const boolean = {
  'YES': 'yes',
  'NO': 'no'
}

// Helpers pour récupérer les valeurs dans le modèle
function getValue(variable) {
  if (
    variable !== undefined &&
    variable !== null &&
    variable.name
  ) {
    return variable.name
  }

  return variable;
}
// Used i résume
function getLabel(variable) {
  if (
    variable !== undefined &&
    variable !== null &&
    variable.label
  ) {
    return variable.label
  }

  return null;
}
function getLabelLower(variable) {
  let label = getLabel(variable)
  if (label != null) {
    return label.toLowerCase()
  }

  return null;
}
function isJNSP(variable) {
  return getValue(variable) === choice.JNSP
}
function isYes(variable) {
  return getValue(variable) === boolean.YES
}
function isNo(variable) {
  return getValue(variable) === boolean.NO
}
function isNull(variable) {
  return getValue(variable) === null
}
function isNotNull(variable) {
  return getValue(variable) !== null
}
function inArray(array, code) {
  return array.filter(item => item.name === code).length === 1
}

export { choice }
export { boolean }
export { getValue }
export { getLabel }
export { getLabelLower }
export { isYes }
export { isNo }
export { isJNSP }
export { isNull }
export { isNotNull }
export { inArray }
