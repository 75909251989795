<template>
    <div :class="{[$style.linkListCard]: true, [$style.linkListCardOpen]: getIsOpen}">
      <img :src="travaux.img" :class="$style.linkListCardImage"/>
      <div :class="$style.linkListCardContent">
        <div :class="$style.title" @click="getIsOpen = !getIsOpen">
          <p :style="{'padding-left' : getIsOpen ? '12px' : '19px'}">
            {{ travaux.label }}
            <img v-if="travaux.picto" :src="travaux.picto" :class="$style.picto">
          </p>
        </div>
        <ul>
          <li
          v-for="(sousTravaux) in travaux.items"
          :key="sousTravaux.name"
          @click="$emit('click', sousTravaux.code)"
          @mouseover="sousTravauxHoverName = sousTravaux.name"
          @mouseleave="sousTravauxHoverName = null"
          >
            <div :class="$style.linkArrow">
              {{ sousTravaux.label }}
              <svg v-if="sousTravauxHoverName === sousTravaux.name" xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.822461 5.10444H9.39476L6.44456 8.09519C6.22979 8.31292 6.22979 8.66599 6.44442 8.88358C6.6592 9.10131 7.00762 9.10131 7.2224 8.88358L11.1113 4.94108C11.1369 4.91515 11.1597 4.88658 11.1798 4.85619C11.189 4.84211 11.1955 4.82692 11.2034 4.81228C11.2125 4.795 11.223 4.77855 11.2302 4.76029C11.2381 4.74147 11.2425 4.72196 11.248 4.70244C11.2525 4.68697 11.2584 4.6722 11.2616 4.6563C11.2687 4.62006 11.2725 4.58354 11.2725 4.54688C11.2725 4.54647 11.2723 4.54605 11.2723 4.54563C11.2722 4.50953 11.2686 4.47329 11.2616 4.43774C11.2583 4.42102 11.2521 4.40554 11.2473 4.38909C11.2419 4.37056 11.2378 4.35174 11.2304 4.33376C11.2224 4.31438 11.2117 4.29682 11.2018 4.27856C11.1944 4.2649 11.1884 4.25096 11.1799 4.23786C11.1598 4.20705 11.1367 4.17834 11.111 4.15227L7.22226 0.210188C7.00749 -0.00753929 6.6592 -0.00753931 6.44442 0.210048C6.22965 0.427775 6.22965 0.78085 6.44442 0.998716L9.39462 3.98932H0.822461C0.518723 3.98932 0.272461 4.23897 0.272461 4.54688C0.272461 4.8548 0.518723 5.10444 0.822461 5.10444Z" fill="#49A0A2"/>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.37129 5.05757H9.94359L6.99339 8.04832C6.77861 8.26604 6.77861 8.61912 6.99325 8.83671C7.20803 9.05443 7.55645 9.05443 7.77123 8.83671L11.6601 4.8942C11.6857 4.86828 11.7085 4.8397 11.7286 4.80931C11.7378 4.79524 11.7443 4.78004 11.7523 4.76541C11.7613 4.74812 11.7718 4.73167 11.7791 4.71341C11.7869 4.6946 11.7913 4.67508 11.7968 4.65557C11.8014 4.6401 11.8073 4.62532 11.8104 4.60943C11.8176 4.57319 11.8213 4.53667 11.8213 4.50001C11.8213 4.49959 11.8212 4.49917 11.8212 4.49875C11.821 4.46265 11.8174 4.42641 11.8104 4.39087C11.8071 4.37414 11.8009 4.35867 11.7961 4.34222C11.7908 4.32368 11.7866 4.30486 11.7792 4.28688C11.7712 4.26751 11.7605 4.24994 11.7506 4.23168C11.7432 4.21802 11.7373 4.20408 11.7288 4.19098C11.7087 4.16018 11.6856 4.13146 11.6599 4.1054L7.77109 0.163313C7.55631 -0.0544143 7.20803 -0.0544143 6.99325 0.163173C6.77848 0.3809 6.77848 0.733975 6.99325 0.951841L9.94345 3.94245H1.37129C1.06755 3.94245 0.821289 4.1921 0.821289 4.50001C0.821289 4.80792 1.06755 5.05757 1.37129 5.05757Z" fill="#1B1918"/>
              </svg>
            </div>
          </li>
        </ul>
        <p v-if="travaux.code" :class="$style.moreLink" @click="$emit('click', travaux.code)">Voir tout</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'HomeLinkList',
  props: {
    travaux: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      sousTravauxHoverName: null,
      getIsOpen: this.isOpen
    }
  },
}
</script>

<style lang="scss" module>
.link-list-card {
  height: 215px;
  max-height: 215px;
  max-width: 340px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  transition: max-height .3s ease-out;
  .link-list-card-image {
    width: 50px;
    height: 215px;
  }
  .link-list-card-content {
    width: 100%;
    position: relative;
    .more-link {
      position: absolute;
      padding-left: 12px;
      font-size: 14px;
      line-height: 25px;
      color: #56ABAE;
      transition: opacity .3s ease-out;
      &:hover {
        cursor: pointer;
        text-decoration-line: underline;
      }
    }
    .title {
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E4E4;
    position: relative;
    transition: border-bottom-color .3s ease-out;
      >p {
        font-size: 20px;
        line-height: 32.5px;
        font-weight: 700;
        font-style: normal;
        color: #1B1918;
        padding: 10px 0 7px 10px;
      }
    }
    >ul {
      list-style: none;
      padding-left: 12px;
      margin: 0 auto;
      display: block;
      padding-top: 14.5px;
      >li {
        &:hover {
          color: #49A0A2;
          cursor: pointer;
          text-decoration-line: underline;
        }
        transition: all .2s;
        font-size: 14px;
        line-height: 23px;
        font-weight: 400;
        color: #1B1918;
        >.linkArrow {
          display: inline-flex;
          align-items: center;
          gap: 8px;
          &:hover {
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .link-list-card {
    max-height: 50px;
    .picto {
      display: none;
    }
    &.link-list-card-open {
      max-height: 215px;
      .link-list-card-content {
        .more-link {
          pointer-events: auto;
          opacity: 1;
        }
        .title {
          border-bottom-color: rgba(228, 228, 228, 1);
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }
    .link-list-card-content {
      .more-link {
        pointer-events: none;
        opacity: 0;
      }
      .title {
        cursor: pointer;
        border-bottom-color: rgba(228, 228, 228, 0);
        &:after {
          content: '';
          position: absolute;
          top: 15px;
          bottom: 15px;
          right: 34px;
          width: 20px;
          height: 20px;
          background: center / 16.25px 10px no-repeat url('../../assets/chevron-2.svg');
          transition: transform .3s ease-out;
        }
        >p {
          font-size: 16px;
        }
      }
      >ul {
        padding-top: 12.5px;
      }
    }
  }
}
</style>
