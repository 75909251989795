<template>
  <div :class="$style.linkPieceCard" @click="$emit('click')">
    <img :class="$style.linkPieceCardImg" :src="travaux.img" :alt="travaux.label" />
    <div :class="$style.linkPieceCardInfos">
      <p :class="$style.linkPieceCardTitle" v-html="travaux.label"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomelinkPiece',
  props: {
    travaux: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.link-piece-card {
  cursor: pointer;
  width: 106px;
  height: 132px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  background: #ffffff;
  .link-piece-card-img {
    width: 106px;
    height: 100px;
  }
  .link-piece-card-infos {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    .link-piece-card-title {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1B1918;
      &:hover {
        color: #49A0A2;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 767px) {
    width: 102px;
    .link-piece-card-img {
      width: 102px;
    }
  }
}
</style>
