<template>
  <div :class="$style.linkGlobalCardAlternative2023" @click="$emit('click')" @mouseover="isHover = true" @mouseleave="isHover = false">
    <img :class="$style.linkGlobalCardImg" :src="travaux.img" :alt="travaux.label" />
    <div :class="$style.linkGlobalCardInfos">
      <img v-if="travaux.picto" :class="$style.linkGlobalCardPicto" :src="travaux.picto" :alt="travaux.label" />
      <p :class="{[$style.linkGlobalCardTitle]: true, [$style.hover]: isHover, [$style.linkGlobalCardTitlePicto]: travaux.picto}" v-html="travaux.label"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeLinkGlobal',
  props: {
    travaux: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isHover: false
    }
  }
}
</script>

<style lang="scss" module>
.link-global-card-alternative-2023 {
  cursor: pointer;
  width: 157.5px;
  height: 150px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.40);
    cursor: pointer;
  }
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  background: #ffffff;
  .link-global-card-img {
    width: 157.5px;
    height: 100px;
  }
  .link-global-card-infos {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    position: relative;
    .link-global-card-picto {
      position: absolute;
      left: 4px;
    }
    .link-global-card-title {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: normal;
      font-style: normal;
      color: #1B1918;
    }
    .link-global-card-title-picto {
      margin-left: 14px;
    }
    .hover {
      color: #49A0A2;
    }
  }
  @media (max-width: 767px) {
    width: 152px;
    .link-global-card-img {
      width: 152px;
    }
  }
}
</style>
