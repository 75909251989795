<template>
  <div :class="$style.homeAlternative">
    <div :class="$style.head">
      <div :class="$style.explanation">
        <div :class="$style.explanationLine"></div>
        <div :class="$style.step">
          <span :class="$style.number">1</span>
          <p :class="$style.description">
            Décrivez et estimez<br><b>vos travaux</b>
          </p>
        </div>
        <div :class="$style.step">
          <span :class="$style.number">2</span>
          <p :class="$style.description">
            Validez votre projet<br>avec <b>un conseiller</b>
          </p>
        </div>
        <div :class="$style.step">
          <span :class="$style.number">3</span>
          <p :class="$style.description">
            <b>Choisissez les Pros</b> que <br>vous voulez rencontrer
          </p>
        </div>
      </div>
    </div>
    <div :class="$style.content">
      <h1 :class="$style.title" v-html="'Quels travaux souhaitez&#8209;vous&nbsp;réaliser&nbsp;?'"></h1>
      <h2 :class="$style.subtitle">Travaux globaux</h2>
      <div :class="$style.linkGlobalContainer">
        <home-link-global
          v-for="travaux in travauxGlobaux"
          :key="travaux.name"
          :travaux="travaux"
          v-on:click="goToCode(travaux.code)">
        </home-link-global>
      </div>
      <h2 :class="$style.subtitle">OU <br> Travaux 1 seule pièce</h2>
      <div :class="$style.linkPieceContainer">
        <home-link-piece
          v-for="travaux in travauxPiece"
          :key="travaux.name"
          :travaux="travaux"
          v-on:click="goToCode(travaux.code)">
        </home-link-piece>
      </div>
      <a :class="$style.linkAllPiece" @click="goToCode('landing_piece')">Voir toutes les pièces</a>
      <h2 :class="$style.subtitle">OU <br> Travaux à la carte</h2>
      <div :class="$style.linkListContainer">
        <home-link-list
          v-for="travaux in autresTravauxLinks"
          :key="travaux.name"
          :travaux="travaux"
          v-on:click="goToCode">
        </home-link-list>
      </div>
    </div>
  </div>
</template>

<script>
import HomeLinkList from '../components/form_link/HomeLinkList'
import HomeLinkGlobal from '../components/form_link/HomeLinkGlobal'
import HomeLinkPiece from '../components/form_link/HomeLinkPiece'

import navigationMixin from '../mixins/navigation-mixin'
import analyticsMixin from '../mixins/analytics-mixin'

import DescriptionProjet from '../assets/home/explanation/description-projet.svg'
import ValiderProjet from '../assets/home/explanation/valider-projet.svg'
import ChoisirPro from '../assets/home/explanation/choisir-pro.svg'

import RenovationEnergetiquePicto from '../assets/home/picto/reno-energetique-picto.png'

import MaisonImg from '../assets/home/renovations/maison.png'
import AppartementImg from '../assets/home/renovations/appartement.png'
import AccessGlobalImg from '../assets/home/renovations/access.png'
import RenoEnergetiqueImg from '../assets/home/renovations/reno-energetique.png'

import GarageImg from '../assets/home/travaux_pieces/garage.png'
import ComblesImg from '../assets/home/travaux_pieces/combles.png'
import CuisineImg from '../assets/home/travaux_pieces/cuisine.png'
import SalleDeBainImg from '../assets/home/travaux_pieces/salle_de_bain.png'
import SalonImg from '../assets/home/travaux_pieces/salon.png'
import ToilettesImg from '../assets/home/travaux_pieces/toilettes.png'

import FenetrePortesImg from '../assets/home/autres_travaux/fenetres-portes.jpg'
import PeintureRevetementsImg from '../assets/home/autres_travaux/peinture-revetements.jpg'
import ChauffageClimatisationImg from '../assets/home/autres_travaux/chauffage-climatisation.jpg'
import TerrasseExterieurImg from '../assets/home/autres_travaux/terrasse-exterieur.jpg'
import FacadeImg from '../assets/home/autres_travaux/facade.jpg'
import PlomberieImg from '../assets/home/autres_travaux/plomberie.jpg'
import IsolationImg from '../assets/home/autres_travaux/isolation.jpg'
import VerriereImg from '../assets/home/autres_travaux/verriere.jpg'
import GrosOeuvreImg from '../assets/home/autres_travaux/gros-oeuvre.jpg'
import ToitureImg from '../assets/home/autres_travaux/toiture.jpg'
import ElectriciteImg from '../assets/home/autres_travaux/electricite.jpg'
import AccessImg from '../assets/home/autres_travaux/accessibilite.jpg'
import RenovationEnergetiqueTravaux from '../assets/home/autres_travaux/renovation-energetique.jpg'

export default {
  name: 'Home',
  components: {
    HomeLinkList,
    HomeLinkGlobal,
    HomeLinkPiece
  },
  mixins: [navigationMixin, analyticsMixin],
  data () {
    return {
      DescriptionProjet,
      ValiderProjet,
      ChoisirPro,
      travauxGlobaux: [
        {
          name: 'renovation_maison',
          code: 'PACK_FUNNEL_MAISON',
          label: 'Rénovation<br>Maison',
          img: MaisonImg
        },
        {
          name: 'renovation_appartement',
          code: 'PACK_FUNNEL_APPARTEMENT',
          label: 'Rénovation<br>Appartement',
          img: AppartementImg
        },
        {
          name: 'access',
          code: 'landing_accessibilite',
          label: 'Accessibilité<br>Adaptation',
          img: AccessGlobalImg
        },
        {
          name: 'renovation_energetique',
          code: 'PACK_RENOVATION_ENERGETIQUE',
          label: 'Economies<br>d\'énergie',
          img: RenoEnergetiqueImg,
          picto: RenovationEnergetiquePicto
        }
      ],
      travauxPiece: [
        {
          name: 'piece_combles',
          code: 'PACK_FUNNEL_COMBLES',
          label: 'Combles',
          img: ComblesImg
        },
        {
          name: 'piece_toilettes',
          code: 'PACK_FUNNEL_WC',
          label: 'Toilettes',
          img: ToilettesImg
        },
        {
          name: 'piece_sdb',
          code: 'PACK_FUNNEL_SDB',
          label: 'Salle de bain',
          img: SalleDeBainImg
        },
        {
          name: 'piece_salon',
          code: 'PACK_FUNNEL_SEJOUR',
          label: 'Salon',
          img: SalonImg
        },
        {
          name: 'piece_cuisine',
          code: 'PACK_FUNNEL_CUISINE',
          label: 'Cuisine',
          img: CuisineImg
        },
        {
          name: 'piece_garage',
          code: 'PACK_PIECE_GARAGE',
          label: 'Garage',
          img: GarageImg
        }
      ],
      autresTravauxLinks: [
        {
          name: 'plomberie',
          label: 'Salle de bain - WC',
          img: PlomberieImg,
          items: [
            {
              name: 'sdb',
              code: 'PACK_FUNNEL_SDB',
              label: 'Salle de bain complète'
            },
            {
              name: 'baignoire',
              code: 'PACK_FUNNEL_SIMPLE_BAIGNOIRE',
              label: 'Baignoire'
            },
            {
              name: 'douche',
              code: 'PACK_FUNNEL_SIMPLE_DOUCHE',
              label: 'Douche'
            },
            {
              name: 'meuble_sdb',
              code: 'PACK_FUNNEL_SIMPLE_MEUBLES_SDB',
              label: 'Meuble salle de bain'
            },
            {
              name: 'radiateur_seche_serviettes',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-RADIATEUR',
              label: 'Radiateur - Sèche-serviettes'
            },
            {
              name: 'WC',
              code: 'PACK_FUNNEL_WC',
              label: 'WC'
            }
          ]
        },
        {
          name: 'TERRASSE_EXTERIEUR',
          // code: 'PACK_AMENAGEMENT_EXTERIEUR',
          label: 'Terrasse - Extérieur',
          img: TerrasseExterieurImg,
          items: [
            {
              name: 'terrasse',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-TERRASSE',
              label: 'Terrasse'
            },
            {
              name: 'allee',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-ALLEE',
              label: 'Allée'
            },
            {
              name: 'cloture',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-CLOTURE',
              label: 'Clôture'
            },
            {
              name: 'mur_cloture',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-MUR_CLOTURE',
              label: 'Mur de clôture'
            },
            {
              name: 'portail',
              code: 'PACK_AMENAGEMENT_EXTERIEUR-PORTAIL',
              label: 'Portail'
            }
          ]
        },
        {
          name: 'peinture_revetement',
          label: 'Peinture - Revêtements',
          img: PeintureRevetementsImg,
          items: [
            {
              name: 'beton_cire',
              code: 'PACK_SIMPLE_BETON_CIRE',
              label: 'Béton ciré'
            },
            {
              name: 'carrelage',
              code: 'PACK_FUNNEL_SIMPLE_CARRELAGE',
              label: 'Carrelage'
            },
            {
              name: 'parquet',
              code: 'PACK_FUNNEL_SIMPLE_PARQUET_BOIS',
              label: 'Parquet et rénovation de parquet'
            },
            {
              name: 'peinture_interieure',
              code: 'PACK_FUNNEL_SIMPLE_PEINTURE_INTERIEURE',
              label: 'Peinture intérieure'
            },
            {
              name: 'sol_stratifie_sol_pvc',
              code: 'PACK_FUNNEL_SIMPLE_SOL_STRAT',
              label: 'Sol stratifié et sol PVC'
            }
          ]
        },
        {
          name: 'chauffage',
          label: 'Chauffage - Climatisation',
          legend: 'Chaudière, radiateur…',
          img: ChauffageClimatisationImg,
          code: 'PACK_CHAUFFAGE_CLIMATISATION',
          items: [
            {
              name: 'chaudiere',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-CHAUDIERE',
              label: 'Chaudière'
            },
            {
              name: 'climatisation',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-CLIMATISATION',
              label: 'Climatisation'
            },
            {
              name: 'poele_insert_bois',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-POELE_INSERT_BOIS',
              label: 'Poêle et insert à bois'
            },
            {
              name: 'pompe_a_chaleur',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-POMPE_A_CHALEUR',
              label: 'Pompe à chaleur'
            },
            {
              name: 'ventilation',
              code: 'PACK_CHAUFFAGE_CLIMATISATION-VENTILATION',
              label: 'Ventilation'
            }
          ]
        },
        {
          name: 'pack_menuiserie',
          code: 'PACK_MENUISERIE',
          label: 'Fenêtres - Portes - Escalier',
          img: FenetrePortesImg,
          items: [
            {
              name: 'baie_coulissante',
              code: 'PACK_MENUISERIE-BAIE_COULISSANTE',
              label: 'Baie coulissante'
            },
            {
              name: 'fenetre',
              code: 'PACK_MENUISERIE-FENETRE',
              label: 'Fenêtre'
            },
            {
              name: 'escalier',
              code: 'PACK_MENUISERIE-ESCALIER',
              label: 'Escalier'
            },
            {
              name: 'porte_fenetre',
              code: 'PACK_MENUISERIE-PORTE_FENETRE',
              label: 'Porte-fenêtre'
            },
            {
              name: 'porte_dentree',
              code: 'PACK_MENUISERIE-PORTE_ENTREE',
              label: 'Porte d\'entrée'
            }
          ]
        },
        {
          name: 'ISOLATION',
          label: 'Isolation',
          img: IsolationImg,
          items: [
            {
              name: 'COMBLES_AMENAGES',
              code: 'PACK_ISOLATION-COMBLES_AMENAGES',
              label: 'Combles aménagés'
            },
            {
              name: 'COMBLES_PERDUS',
              code: 'PACK_ISOLATION-COMBLES_PERDUS',
              label: 'Combles perdus'
            },
            {
              name: 'toit-terrasse',
              code: 'PACK_ISOLATION-TOIT_TERRASSE',
              label: 'Toit-terrasse'
            },
            {
              name: 'mur',
              code: 'PACK_ISOLATION-MUR',
              label: 'Mur'
            },
            {
              name: 'plancher_intermediaire',
              code: 'PACK_ISOLATION-PLANCHER_INTERMEDIAIRES',
              label: 'Plancher'
            }
          ]
        },
        {
          name: 'facade',
          label: 'Façade',
          img: FacadeImg,
          items: [
            {
              name: 'bardage',
              code: 'PACK_SIMPLE_BARDAGE',
              label: 'Bardage'
            },
            {
              name: 'eduit_crepi',
              code: 'PACK_SIMPLE_ENDUIT_FACADE',
              label: 'Enduit ou crépi'
            },
            {
              name: 'isolation_exterieur',
              code: 'PACK_SIMPLE_ISOLATIONS_MURS_EXTERIEURS',
              label: 'Isolation thermique par l\'extérieur'
            },
            {
              name: 'ravalement',
              code: 'landing_reno_facade',
              label: 'Ravalement'
            }
          ]
        },
        {
          name: 'MUR_CLOISONS',
          label: 'Verrière - Mur - Plafond',
          img: VerriereImg,
          items: [
            {
              name: 'verriere',
              code: 'PACK_SIMPLE_VERRIERE',
              label: 'Verrière'
            },
            {
              name: 'cloison',
              code: 'PACK_FUNNEL_SIMPLE_CLOISON',
              label: 'Cloison'
            },
            {
              name: 'demolition_mur',
              code: 'PACK_FUNNEL_SIMPLE_DEMOLITION_MUR',
              label: 'Démolition de mur'
            },
            {
              name: 'plafond',
              code: 'PACK_FUNNEL_SIMPLE_ISO_PLAFOND',
              label: 'Plafond'
            }
          ]
        },
        {
          name: 'GROS_OEUVRE',
          label: 'Gros oeuvre',
          img: GrosOeuvreImg,
          items: [
            {
              name: 'chape_beton',
              code: 'PACK_FUNNEL_SIMPLE_CHAPE_BETON',
              label: 'Chape béton'
            },
            {
              name: 'dalle_beton',
              code: 'PACK_FUNNEL_SIMPLE_DALLE_BETON',
              label: 'Dalle béton'
            },
            {
              name: 'demolition_mur',
              code: 'PACK_FUNNEL_SIMPLE_DEMOLITION_MUR',
              label: 'Démolition de mur'
            }
          ]
        },
        {
          name: 'toiture',
          code: 'PACK_RENOVATION_TOITURE',
          label: 'Toiture',
          img: ToitureImg,
          items: [
            {
              name: 'charpente',
              code: 'PACK_RENOVATION_TOITURE-CHARPENTE',
              label: 'Charpente'
            },
            {
              name: 'couverture',
              code: 'PACK_RENOVATION_TOITURE-COUVERTURE',
              label: 'Couverture'
            },
            {
              name: 'fenetre_de_toit',
              code: 'PACK_RENOVATION_TOITURE-MENUISERIE',
              label: 'Fenêtre de toit'
            },
            {
              name: 'isolation_thermique',
              code: 'PACK_RENOVATION_TOITURE-ISOLATION',
              label: 'Isolation thermique'
            },
            {
              name: 'etancheite_toit_terrasse',
              code: 'PACK_RENOVATION_TOITURE-ETANCHEITE_TOIT_TERRASSE',
              label: 'Etanchéité toit-terrasse'
            }
          ]
        },
        {
          name: 'ELECTRICITE',
          label: 'Electricité',
          img: ElectriciteImg,
          items: [
            {
              name: 'mise_normes_piece',
              code: 'PACK_FUNNEL_SIMPLE_ELECTRICITE_PIECE',
              label: 'Mise aux normes d\'une pièce'
            },
            {
              name: 'mise_normes_logement',
              code: 'PACK_FUNNEL_SIMPLE_ELECTRICITE_LOGEMENT',
              label: 'Mise aux normes d\'un logement'
            }
          ]
        },
        {
          name: 'RENOVATION_ENERGETIQUE',
          label: 'Rénovation énergétique',
          img: RenovationEnergetiqueTravaux,
          picto: RenovationEnergetiquePicto,
          items: [
            {
              name: 'reno_energetique_chauffage_climatisation',
              code: 'PACK_RENOVATION_ENERGETIQUE-CHAUFFAGE',
              label: 'Chauffage - Climatisation'
            },
            {
              name: 'reno_energetique_isolation',
              code: 'PACK_RENOVATION_ENERGETIQUE-ISOLATION',
              label: 'Isolation'
            },
            {
              name: 'reno_energetique_menuiserie',
              code: 'PACK_RENOVATION_ENERGETIQUE-FENETRES',
              label: 'Fenêtre - Portes'
            },
            {
              name: 'reno_energetique_ventilation',
              code: 'PACK_RENOVATION_ENERGETIQUE-VENTILATION',
              label: 'Ventilation'
            }
          ]
        },
        {
          name: 'ACCESSIBILITE',
          label: 'Accessibilité du logement',
          img: AccessImg,
          items: [
            {
              name: 'accessibilite_sdb',
              code: 'PACK_ACCESSIBILITE_SDB',
              label: 'Salle de bain sécurisée'
            },
            {
              name: 'accessibilite_baignoire_douche',
              code: 'PACK_ACCESSIBILITE_BAIGNOIRE_DOUCHE',
              label: 'Remplacer une baignoire par une douche'
            },
            {
              name: 'accessibilite_toilette',
              code: 'PACK_ACCESSIBILITE_TOILETTE',
              label: 'Toilettes accessibles'
            },
            {
              name: 'accessibilite_ascenseur',
              code: 'PACK_ACCESSIBILITE_ASCENSEUR',
              label: 'Monte-escalier et ascenseur'
            },
            {
              name: 'accessibilite_chambre_rdc',
              code: 'PACK_ACCESSIBILITE_CHAMBRE_RDC',
              label: 'Chambre accessible au RDC'
            },
            {
              name: 'accessibilite_chambre_garage',
              code: 'PACK_ACCESSIBILITE_CHAMBRE_GARAGE',
              label: 'Transformer un garage en chambre'
            }
          ]
        }
      ]
    }
  },
  methods: {
    goToCode (code) {
      this.pushDataLayer({
        eventaction: 'validated-step',
        eventcategory: 'estimation',
        eventlabel: 'accueil',
        interface: 'accueil',
        numerodetape: this.stepIndex,
        BSKU: '',
        label_besoin: '',
        page_pia: 'oui'
      })
      this.goToNextStep(code)
    },
    goToCodeSkipSubFormChoice (code) {
      this.pushDataLayer({
        eventaction: 'validated-step',
        eventcategory: 'estimation',
        eventlabel: 'accueil',
        interface: 'accueil',
        numerodetape: this.stepIndex,
        BSKU: '',
        label_besoin: '',
        page_pia: 'oui'
      })
      this.goToNextStep(code, true)
    }
  },
  mounted () {
    // import du composant formulaire pour le preloader dès que la home est mounted
    import('../views/Formulaire')
      .then(() => {
        console.log('form page preloaded')
      })
    this.$store.commit('setLanding', '')
    this.$store.commit('setAdvancedForm', '')
    if (this.$store.state.navigation.resetNav) {
      this.$store.commit('setResetNav', false)
    } else {
      this.pushDataLayer({
        eventaction: 'display',
        eventcategory: 'estimation',
        eventlabel: 'accueil',
        interface: 'accueil',
        numerodetape: this.stepIndex,
        BSKU: '',
        label_besoin: '',
        page_pia: 'oui'
      })
      this.$store.commit('setResetNav', false)
    }
  }
}
</script>

<style lang="scss" module>
.home-alternative {
  text-align: left;
  padding-bottom: 20px;
  .head {
    max-width: 768px;
    margin: 0 auto;
    padding: 17px 0 33px;
    .explanation {
      margin: 0 auto;
      max-width: 582px;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-around;
      .explanation-line {
        top: 19px;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #49A0A2;
        z-index: 0;
      }
      .step {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 12px 0;
        justify-content: center;
        align-items: center;
        width: fit-content;
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          aspect-ratio: 1/1;
          font-weight: 700;
          font-size: 19.2px;
          line-height: 22px;
          background-color: #2E4057;
          color: #ffffff;
          border-radius: 50%;
        }
        .description {
          width: fit-content;
          font-weight: 300;
          font-size: 13.3px;
          line-height: 15px;
          text-align: center;
          color: #1B1918;
          b {
            font-weight: 500;
          }
        }
      }
    }
    @media (max-width: 767px) {
      padding: 0;
      .explanation {
        display: none;
      }
    }
  }
  .content {
    max-width: 737px;
    width: 100%;
    margin: 10px auto 0;
    .title, .subtitle {
      font-weight: 700;
    }
    .title {
      color: #1B1918;
      font-size: 27.7px;
      line-height: 32px;
      margin-bottom: 32px;
    }
    .subtitle {
      color: #1B1918;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      margin: 32px 0;
    }
    .link-all-piece {
      display: block;
      margin: 20px auto 32px;
      cursor: pointer;
      color: #56ABAE;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
    }
    .link-list-container {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 40px 0;
    }
    .link-global-container {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 29px;
    }
    .link-piece-container {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 20px;
    }
    @media (max-width: 767px) {
      .title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 32px;
      }
      .link-list-container {
        justify-content: center;
        flex-direction: column;
        gap: 12px;
      }
      .link-global-container {
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: calc((152px * 2) + 20px);
        gap: 20px 10px;
      }
      .link-piece-container {
        max-width: calc((102px * 3) + (12px * 2));
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 20px 10px;
      }
    }
    @media (max-width: 388px) {
      .link-piece-container {
        justify-content: center;
        gap: 20px 12px;
      }
    }
    @media (max-width: 376px) {
      .link-global-container {
        justify-content: center;
      }
    }
  }
}

@media (min-width: 1280px) {
  .home-alternative {
    .content {
      position: relative;
    }
  }
}
</style>
