<template>
    <div :class="{[$style.linkListCard]: true, [$style.linkListCardOpen]: isOpen}">
      <img :src="travaux.img" :class="$style.linkListCardImage"/>
      <div :class="$style.linkListCardContent">
        <div :class="$style.title" @click="isOpen = !isOpen">
          <p>
            {{ travaux.label }}
            <img v-if="travaux.picto" :src="travaux.picto" :class="$style.picto">
          </p>
        </div>
        <ul>
          <li
          v-for="sousTravaux in travaux.items"
          :key="sousTravaux.name"
          @click="$emit('click', sousTravaux.code)"
          >
            {{ sousTravaux.label }}
          </li>
        </ul>
        <p v-if="travaux.code" :class="$style.moreLink" @click="$emit('click', travaux.code)">Voir tout</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'HomeLinkList',
  props: {
    travaux: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    }
  }
}
</script>

<style lang="scss" module>
.link-list-card {
  height: 215px;
  max-height: 215px;
  max-width: 340px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  transition: max-height .3s ease-out;
  .link-list-card-image {
    width: 50px;
    height: 215px;
  }
  .link-list-card-content {
    width: 100%;
    position: relative;
    .more-link {
      position: absolute;
      bottom: 5px;
      padding-left: 12px;
      font-size: 14px;
      line-height: 25px;
      color: #56ABAE;
      transition: opacity .3s ease-out;
      &:hover {
        cursor: pointer;
      }
    }
    .title {
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E4E4;
    position: relative;
    transition: border-bottom-color .3s ease-out;
    >p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      color: #333333;
      padding: 5px 0 5px 12px;
    }
    }
    >ul {
      list-style: none;
      padding-left: 12px;
      margin: 0px auto;
      display: block;
      >li {
        &:hover {
          color: #49A0A2;
          cursor: pointer;
        }
        transition: all .2s;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 767px) {
  .link-list-card {
    max-height: 50px;
    .picto {
      display: none;
    }
    &.link-list-card-open {
      max-height: 215px;
      .link-list-card-content {
        .more-link {
          pointer-events: auto;
          opacity: 1;
        }
        .title {
          border-bottom-color: rgba(228, 228, 228, 1);
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }
    .link-list-card-content {
      .more-link {
        pointer-events: none;
        opacity: 0;
      }
      .title {
        cursor: pointer;
        border-bottom-color: rgba(228, 228, 228, 0);
        &:after {
          content: '';
          position: absolute;
          top: 15px;
          bottom: 15px;
          right: 34px;
          width: 20px;
          height: 20px;
          background: center / 16.25px 10px no-repeat url('../../assets/chevron-2.svg');
          transition: transform .3s ease-out;
        }
        >p {
          font-size: 16px;
        }
      }
      >ul {
        margin-top: 6px;
      }
    }
  }
}
</style>
