import Vue from 'vue'
import App from './App.vue'
import VueCustomElement from 'vue-custom-element'
import MediaQuery from 'vue-match-media/dist/index'
import store from './store/index'
import 'lazysizes'
import ErrorService from './services/error/ErrorService'
import * as Sentry from '@sentry/vue'
// import exlFilter from './exlFilter'
import VueAnalytics from 'vue-analytics'
import 'document-register-element/build/document-register-element'
import 'promise-polyfill/src/polyfill'
import 'core-js/es/set'
import 'core-js/es/array/from'
import 'url-polyfill/url-polyfill.min'
import 'picturefill/dist/picturefill.min'
import 'polyfill-array-includes'

document.isTouchDevice = function () {
  try {
    document.createEvent('TouchEvent')
    return true
  } catch (e) {
    return false
  }
}

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  integrations: function (integrations) {
    // Désactivation de toutes les intégrations qui capture automatiquement les erreurs mais activations de celles qui
    // sont utiles : https://docs.sentry.io/platforms/javascript/guides/ember/configuration/integrations/default/
    return integrations.filter(function (integration) {
      return integration.name !== 'GlobalHandlers' &&
        integration.name !== 'InboundFilters' &&
        integration.name !== 'TryCatch'
    })
  },
  sampleRate: 1
})

Vue.use(VueCustomElement)
Vue.use(MediaQuery)
Vue.use(VueAnalytics, {
  id: 'UA-57741382-8'
})
// Vue.filter('evaluate', exlFilter)

Vue.filter('formatNumber', function (value) {
  if (!value) return ''
  return value.toLocaleString()
})

Vue.config.errorHandler = (err, vm, info) => {
  ErrorService.manageCallbackError(err, vm)
  console.error(err)
}

Vue.config.productionTip = false
App.store = store
Vue.customElement('taro-funnel', App)
