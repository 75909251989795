import bskuConfig from '../../config/bsku.json'
import redirectBskuConfig from '../../config/redirect_bsku.json'

export default {
  isBsku (bsku) {
    return Object.keys(bskuConfig.bskus).indexOf(bsku) > -1
  },
  isBskuRedirect (bsku) {
    return Object.keys(redirectBskuConfig.redirected_bskus).indexOf(bsku) > -1
  },
  isBskuRedirectToLanding (bsku) {
    if (!this.isBskuRedirect(bsku)) {
      return false
    }
    return this.getFormCode(bsku).split('_')[0] === 'landing'
  },
  isBskuCode (bskuCode) {
    if (bskuCode && bskuCode.indexOf('-') !== -1) {
      bskuCode = bskuCode.substring(0, bskuCode.indexOf('-'))
    }
    return Object.entries(bskuConfig.bskus).find((item) => item[1].code === bskuCode) !== undefined
  },
  isValid (bsku) {
    return this.isBsku(bsku) ||
      this.isBskuRedirect(bsku) ||
      this.isBskuCode(bsku)
  },
  getFormFromCode (bskuCode) {
    if (bskuCode.indexOf('-') !== -1) {
      return bskuCode.substring(0, bskuCode.indexOf('-'))
    }
    return bskuCode
  },
  getSubFormFromCode (bskuCode) {
    if (bskuCode.indexOf('-') === -1) {
      return undefined
    }
    return bskuCode.substring(bskuCode.indexOf('-') + 1)
  },
  getFormCode (bsku) {
    if (this.isBsku(bsku)) {
      return bskuConfig.bskus[bsku].code
    }
    if (this.isBskuRedirect(bsku)) {
      return this.getFormFromCode(redirectBskuConfig.redirected_bskus[bsku].code_redirect)
    }
    if (this.isBskuCode(bsku)) {
      return this.getFormFromCode(bsku)
    }
    return bsku
  },
  getSubFormCode (bsku) {
    if (this.isBskuRedirect(bsku)) {
      return this.getSubFormFromCode(redirectBskuConfig.redirected_bskus[bsku].code_redirect)
    }
    if (this.isBskuCode(bsku)) {
      return this.getSubFormFromCode(bsku)
    }
  }
}
